Customner<template>
  <div class="products">
    <div class="page-header">
      <h1>{{ $t('Customer Management')}}</h1>
    </div>
    <div class="page-content">
      <div class="content-header">
        <h2>{{ $t('Customers') }}</h2>
      </div>
      <ItemFilter :placeholder="$t('Search by customer name, email, phone')" default="customerName" @search="searchItems">
        <el-option :label="$t('Customer Name Search')" value="customerName"></el-option>
        <el-option :label="$t('Email Search')" value="customerEmail"></el-option>
        <el-option :label="$t('Phone Number Search')" value="customerPhone"></el-option>
      </ItemFilter>

      <div class="inventory-list">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">{{ $t('Name') }}</th>
              <th scope="col">{{ $t('Email') }}</th>
              <th scope="col">{{ $t('Phone Number')}}</th>
              <th scope="col">{{ $t('Order Count')}}</th>
              <th scope="col">{{ $t('Total Spend') }}</th>
              <th scope="col">{{ $t('Actions') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="customer in customers" v-if="customer.customer_name">
              <td>{{ customer.customer_name }}</td>
              <td>{{ customer.customer_email }}</td>
              <td>{{ customer.customer_phone }}</td>
              <td>{{ customer.order_count }}</td>
              <td>HK$ {{ customer.total_spend }}</td>
              <td>
                <div class="action-links">
                  <router-link class="action-link" :to="{name:'Customer', params: {id: customer.id}}">
                    {{ $t('Edit') }}
                  </router-link>
                  <a class="action-link danger" v-if="customer.order_count === 0 " href="#" @click="deleteCustomer(customer.id)">{{ $t('Delete') }}</a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ItemFilter from '@/components/Filter.vue';
import Common from '@/lib/common';
import Customer from '@/lib/customer';

export default {
  name: 'Customers',
  components: {
    ItemFilter,
  },
  mounted(){
    this.loadAllCustomers();
  },
  methods:{
    searchItems(form){
      const searchField = {};
      let newItems = [];
      switch(form.filterTarget){
        case 'customerName':
        searchField['customer_name'] = form.filterValue;
        newItems = Common.filterItems(searchField, this.orignalCustomers);
        this.customers = newItems;
        break;

        case 'customerEmail':
        searchField['customer_email'] = form.filterValue;
        newItems = Common.filterItems(searchField, this.orignalCustomers);
        this.customers = newItems;
        break;

        case 'customerPhone':
        searchField['customer_phone'] = form.filterValue;
        newItems = Common.filterItems(searchField, this.orignalCustomers);
        this.customers = newItems;
        break;
      }
    },
    async deleteCustomer(customerId){
      try{
        const loginInfo = Common.getLoginInfo();
        await Customer.deleteCustomer(this.apiUrl, customerId, loginInfo);
        const newCustomer = this.customers.filter(function(item) {
          return item.id !== customerId;
        });
        this.orignalCustomer = newCustomer;
        this.customers = newCustomer;
      }catch(err){
        console.log(err);
      }
    },
    async loadAllCustomers(){
      try{
        const loginInfo = Common.getLoginInfo();
        const customers = await Customer.loadAllCustomers(this.apiUrl, loginInfo);
        this.orignalCustomers = customers;
        this.customers = customers;
      }catch(err){
        console.log(err);
      }
    },
  },
  data(){
    return {
      customers: [],
      orignalCustomers: [],
    }
  },
  computed: mapState({
   apiUrl: state => state.apiUrl,
 }),
}
</script>

<style scoped lang="scss">
.content-header{
  display: flex;
  h2{
    flex: 1;
  }
  .action-button{
    margin-right: 20px;
  }
}
</style>
